import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { motion } from "framer-motion"
import Button from "_components/buttons/button_parallax"
import Footer from "_components//nav/footer/_footer"
import Header from "_components/nav/header/_header"
import PageHeadline from "_components/misc/pageHeadline"
import styled from "styled-components"
import { color_primButton } from "_src/assets/data/global_colors"
import { Paragraph, Screen, HeaderContainer } from "_data/global_styles"

const Session = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <div style={{ maxWidth: "700px" }}>
            <motion.div
              style={{ zIndex: "2" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.55, duration: 0.5 }}
            >
              <PageHeadline copy="Hello.  How are you?" />
              <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
                <Paragraph>
                  Are you a client here to meet your therapist for a virtual
                  session?
                  {/* Or, are you
                a therapist meeting your client? */}
                  {/* Are you a client arriving for an appointment with your
                  therapist? Or, are you a therapist connecting with your
                  client? In the future, you can skip this step by bookmarking
                  the{" "}
                  <Link
                    style={{
                      color: color_textLink,
                      fontWeight: "800",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    to="/therapist"
                  >
                    Therapist Login
                  </Link>{" "}
                  or{" "}
                  <Link
                    style={{
                      color: color_textLink,
                      fontWeight: "800",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    to="/client"
                  >
                    Client Login
                  </Link>{" "}
                  links directly. */}
                </Paragraph>
              </div>
              <Buttons>
                <Button1>
                  <Button
                    color="#754ebb"
                    shadowColor="black"
                    copy="Yes, I am a client!"
                    destination="/client"
                    height={60}
                    width={220}
                    noParallax={true}
                  />
                </Button1>
                <Button2>
                  <Button
                    color={color_primButton}
                    shadowColor="black"
                    copy="No, I'm a therapist."
                    destination="/therapist"
                    height={60}
                    width={220}
                    noParallax={true}
                  />
                </Button2>
              </Buttons>
            </motion.div>
          </div>
        </Content>
        <Footer />
      </Screen>
    </>
  )
}

const Content = styled.main`
  width: 80%;
  max-width: 800px;
  padding-top: 10vh;
  padding-bottom: 10vh;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2vh;
  width: 100%;
  justify-content: left;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const Button1 = styled.div`
  /* perspective: 500px;
  transform: rotate(2deg); */
`

const Button2 = styled.div`
  margin-left: 20px;
  @media only screen and (max-width: 500px) {
    margin-left: 0px;
    margin-top: 10px;
  }
`
export default Session
